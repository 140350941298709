<template>
  <el-select
    v-model="selected"
    :class="[
      `client-status-table-${selected}`,
      selected === 'active'
        ? 'status-green'
        : selected === 'removing_requested' ? 'status-orange' : 'status-red',
    ]"
    :disabled="selected === ClientsStatuses.removed"
    @change="changeHandler"
    @click.prevent
  >
    <el-option
      v-for="item in items"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :disabled="
        (
          item.value === ClientsStatuses.request || 
          (item.value === ClientsStatuses.removed && firstStateStatus !== ClientsStatuses.request) ||
          (item.value === ClientsStatuses.blocked && firstStateStatus === ClientsStatuses.request)
        ) && 
          !roleChecker(ROLE.SUPERADMIN)"
    />
  </el-select>
</template>

<script lang="ts" setup>
import {
  defineProps,
  PropType,
  ref,
  watch,
} from 'vue';
import { magnerConfirm } from 'magner';
import { tableStatusUpdate } from 'features/users/clients/requests';
import { Client } from 'features/users/clients';
import { ClientsStatuses } from '~/types/common/clients';
import { roleChecker } from '~/utils/role-checker';
import { ROLE } from '~/constants';

const props = defineProps({
  row: {
    type: Object as PropType<Client>,
    required: true,
  },
});

const items = [
  {
    label: 'Активный',
    value: ClientsStatuses.active,
  },
  {
    label: 'Заблокирован',
    value: ClientsStatuses.blocked,
  },
  {
    label: 'Запрос на удаление',
    value: ClientsStatuses.request,
  },
  {
    label: 'Удален',
    value: ClientsStatuses.removed,
  },
];

const firstStateStatus = ref(props.row.status.value);
const selected = ref(props.row.status.value);

const changeHandler = async (value: string) => {
  if (value === ClientsStatuses.removed) {
    magnerConfirm({
      message: 'Вы уверены, что хотите удалить клиента? Данное действие необратимо.',
      title: 'Внимание',
      confirmButtonText: 'Да, удалить',
      cancelButtonText: 'Отмена',
      type: 'warning',
    }).then(async () => {
      await tableStatusUpdate({ id: props.row.id, status: value });

      window.location.reload();
    }).catch(() => {
      selected.value = props.row.status.value;
    });
  }
  else if (value === ClientsStatuses.blocked) {
    magnerConfirm({
      message: 'Вы уверены, что хотите заблокировать клиента?',
      title: 'Внимание',
      confirmButtonText: 'Да, заблокировать',
      cancelButtonText: 'Отмена',
      type: 'warning',
    }).then(async () => {
      await tableStatusUpdate({ id: props.row.id, status: value });

      window.location.reload();
    }).catch(() => {
      selected.value = props.row.status.value;
    });
  }
  else {
    await tableStatusUpdate({ id: props.row.id, status: value });

    window.location.reload();
  }
};

watch(() => props.row.status, (newValue: { value: string }) => {
  selected.value = newValue.value;
  firstStateStatus.value = newValue.value;
});
</script>

<style scoped>
.el-select {
  width: 166px;
}
</style>
